<h1 mat-dialog-title>Selecionar transgressões</h1>
<div mat-dialog-content>
  <div class="row">
    <div class="col-12">
      <h5>Filtros</h5>
    </div>
    <div class="col-lg-6">
      <mat-form-field appearance="outline">
        <mat-label>Artigo</mat-label>
        <mat-select matInput [formControl]="artigoFilter">
          <mat-option value="">Selecionar artigo</mat-option>
          <mat-option
            *ngFor="let artigo of artigos"
            [value]="artigo.descricao"
            >{{ artigo.descricao }}</mat-option
          >
        </mat-select>
        <app-input-loading [isLoading]="artigoLoading"></app-input-loading>
      </mat-form-field>
    </div>
    <div class="col-lg-6">
      <mat-form-field appearance="outline">
        <mat-label>Descricao</mat-label>
        <input
          matInput
          [formControl]="descricaoFilter"
          type="text"
          placeholder="Descricao"
        />
      </mat-form-field>
    </div>
  </div>
  <div class="table-container overflow-auto text-nowrap">
    <table mat-table [dataSource]="dataSource">
      <!-- Checkbox Column -->
      <ng-container matColumnDef="select" *ngIf="!data.unique">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            (change)="$event ? toggleAllRows() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
            color="primary"
          >
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)"
            color="primary"
          >
          </mat-checkbox>
        </td>
      </ng-container>

      <!-- artigo Column -->
      <ng-container matColumnDef="artigo">
        <th mat-header-cell *matHeaderCellDef>Artigo</th>
        <td mat-cell *matCellDef="let element">
          {{ element.artigo.descricao }}
        </td>
      </ng-container>

      <!-- artigo Column -->
      <ng-container matColumnDef="inciso">
        <th mat-header-cell *matHeaderCellDef>Inciso</th>
        <td mat-cell *matCellDef="let element">{{ element.inciso }}</td>
      </ng-container>

      <!-- descricao Column -->
      <ng-container matColumnDef="descricao">
        <th mat-header-cell *matHeaderCellDef>Descrição</th>
        <td mat-cell *matCellDef="let element">{{ element.descricao }}</td>
      </ng-container>

      <!-- penalidade Column -->
      <ng-container matColumnDef="penalidade">
        <th mat-header-cell *matHeaderCellDef>Penalidade</th>
        <td mat-cell *matCellDef="let element">
          {{ element.penalidade?.descricao }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        [ngClass]="{ 'link-tr': data.unique }"
        (click)="handleRowClick(row)"
      ></tr>
    </table>
  </div>
  <mat-paginator [pageSizeOptions]="[5, 10]"></mat-paginator>

  <h5 class="m-3" *ngIf="data.form.value?.length">
    {{
      data.unique ? "Transgressão Selecionada" : "Transgressões Selecionadas"
    }}
  </h5>
  <dynamic-list>
    <dynamic-list-item *ngFor="let tr of data.form.value">
      <div class="d-flex justify-content-between align-items-start fs-11">
        <span
          >{{ tr.artigo.descricao }} - Inciso {{ tr.inciso }} -
          {{ tr.descricao }}</span
        >
        <button
          mat-icon-button
          class="mat-elevation-z2 ml-3"
          color="basic"
          (click)="deleteTransgressao(tr)"
          matTooltip="Remover Transgressao"
          matTooltipClass="example-tooltip-conf"
          matTooltipPosition="above"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </dynamic-list-item>
  </dynamic-list>
</div>

<div mat-dialog-actions>
  <button class="m-2" mat-raised-button color="primary" (click)="onNoClick()">
    Ok
  </button>
</div>
