import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

export type statusType = string | {
  label: string;
  color: string;
  badge?: {
    label: string;
    color: string;
  };
  tooltip?: string;
}

type columnType = 'status' | 'statusMultiple' | 'actionBtns' | 'elipsis' | 'link';


export interface Column {
  def: string;
  header: string;
  type?: columnType;
}

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})

export class TableComponent implements OnInit, OnChanges {

  dataSource: MatTableDataSource<any> =  new MatTableDataSource<any>();
  isProgress: boolean = false;

  @Input() title: string;
  @Input() displayedColumns: string[];
  @Input() columns: Column[];
  @Input() columnFilters: any[];
  @Input() data: any;
  @Input() filter: string;
  @Input() pageSizeOptions: number[];
  @Input() linkTableRow: string;
  @Input() activeRowClick: boolean = false;
  @Input() loading: boolean = false;
  @Input() compact: boolean = false;
  @Output() elementClicked = new EventEmitter<any>();

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private _liveAnnouncer: LiveAnnouncer) { }

  ngOnInit() {
    this.dataSource = new MatTableDataSource<any>(this.data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.filter = this.filter;
    this.dataSource.filterPredicate = this.filterPredicate;
    this.dataSource.sortingDataAccessor = this.sortingDataAccessorFn;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      this.dataSource = new MatTableDataSource<any>(changes.data.currentValue);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.filter = this.filter;
      this.dataSource.filterPredicate = this.filterPredicate;
      this.dataSource.sortingDataAccessor = this.sortingDataAccessorFn;
    }
    if (changes.filter) {
      this.dataSource.filter = changes.filter.currentValue;
    }
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  sortingDataAccessorFn(item: any, property: string): string | number {
    if (item[property]?.codigo) {
      return item[property].codigo;
    }
    if (item[property]?.label) {
      return item[property].label;
    }
    return item[property];
  }

  filterPredicate(data: any, filter: string) {
    const accumulator = (currentTerm: any, key: any) => {
      return key === 'status' ? currentTerm + data[key].label : currentTerm + data[key];
    };
    // 1. Transforma o objeto em uma string concatenada com todos os valores das colunas
    const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
    // 2. Tira espaços em branco e transforma em minúsculo o filtro
    const transformedFilter = filter.trim().toLowerCase();
    // 3. Retorna true se a string concatenada contém o filtro
    return dataStr.indexOf(transformedFilter) !== -1;
  }

  elementRowSelected(row) {
    this.elementClicked.emit(row);
  }
}
