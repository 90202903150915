import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-module-sidenav',
  templateUrl: './module-sidenav.component.html',
  styleUrls: ['./module-sidenav.component.css']
})
export class ModuleSidenavComponent implements OnInit, OnChanges {

  localActive: number;
  size: number;

  @Input() links: {
    label: string,
    icon?: string,
    route: string,
    condition?: boolean,
    disabled?: boolean
  }[]
  @Input() header: string;
  @Input() navToggle: boolean = true;
  @Input() activatedLink: number;

  @Output() hiddenChange = new EventEmitter<boolean>();
  @Output() linkChange = new EventEmitter();
  hidden: boolean = false;
  constructor() { }
  ngOnInit(): void {
    this.links = this.links.filter(l => (l.condition || l.condition !== null || l.condition !== undefined));
    this.size = Math.max(this.links.reduce((a: any, b: any) => a.label.length > b.label.length ? a : b).label.length + 12, 18);
    this.hidden = window.localStorage.getItem('moduleSideNavToggle') === 'true';
    this.hiddenChange.emit(this.hidden);
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.activatedLink && changes.activatedLink.currentValue !== undefined && changes.activatedLink.currentValue !== null) {
      this.localActive = changes.activatedLink.currentValue;
    }
  }
  emitActive(n) {
    this.localActive = n;
    this.linkChange.emit(this.links[n]);
  }
  toggleHidden() {
    this.hidden = !this.hidden;
    this.hiddenChange.emit(this.hidden);
    window.localStorage.setItem('moduleSideNavToggle', this.hidden.toString());
  }
}
