import { Component, ElementRef, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { RhNetService } from 'src/app/services/rhNet.service';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { HelperService } from 'src/app/utils/helper.service';
import { DialogConfirmComponent } from '../../dialogs/dialog-confirm/dialog-confirm.component';
import { Column } from '../../table/table.component';
import { AddServidorComponent } from '../add-servidor/add-servidor.component';
import { ServidorService } from '../servidor.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs';
import { EditServidorComponent } from '../edit-servidor/edit-servidor.component';
@Component({
  selector: 'app-busca-servidor',
  templateUrl: './busca-servidor.component.html',
  styleUrls: ['./busca-servidor.component.css'],
})
export class BuscaServidorComponent implements OnInit, OnChanges {

  @Output() servidor = new EventEmitter<any>();
  @Input() disabled: boolean;
  @Input() isLoading: boolean;
  @Input() vinculoDialog: boolean = true;
  @Input() cadastroNovo: boolean = true;
  @Input() icon: string;
  @Input() label: string;
  @Input() appearance: string;
  @Input() inputClasses: string;
  @Input() buttonTooltip: string;
  @Input() toolTip: string;
  @Input() tooltipPosition: string;
  @ViewChild('input') inputElement: ElementRef;

  cpfForm: UntypedFormControl;
  isProgress: boolean;

  constructor(
    private rhNetService: RhNetService,
    private servidorService: ServidorService,
    private dialog: MatDialog,
    private helper: HelperService,
    private snackBar: SnackBarService
  ) { }

  ngOnInit(): void {
    this.cpfForm = new UntypedFormControl({ value: null, disabled: this.disabled }, [Validators.required, this.helper.cpfValidator]);
  }

  public getForm() {
    return this.cpfForm;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disabled && this.cpfForm) {
      if (changes.disabled.currentValue) {
        this.cpfForm.disable();
      } else {
        this.cpfForm.enable();
      }
    }
  }
  getServidor(cpf) {
    if (this.cpfForm.invalid) {
      this.cpfForm.markAsTouched();
      return;
    }
    this.isProgress = true;
    this.cpfForm.disable();
    this.rhNetService.getServidorByCPF({ cpf: cpf })
      .subscribe((res: any) => {
        this.isProgress = false;
        this.cpfForm.enable();
        this.inputElement.nativeElement.focus();
        if (res.vinculos.length > 1) {
          const cpf = res.cpf
          const dialogRef = this.dialog.open(selectVinculoComponent, {
            width: '100%',
            disableClose: true,
            data: {
              servidor: {
                nome: res.nome,
                cpf: this.formatCpf(res.cpf)
              },
              vinculos: res.vinculos
            }
          });
          dialogRef.afterClosed()
            .subscribe(vinculo => {
              if (vinculo) {
                let servidor = res;
                servidor.vinculo = vinculo;
                this.servidor.emit(servidor);
              }
            });
        } else if (res.vinculos.length === 1) {
          let servidor = res;
          servidor.vinculo = res.vinculos[0];
          this.servidor.emit(servidor);
        } else {
          if (this.cadastroNovo)
            this.openUpdateServidorDialog(res);
          else
            this.snackBar.showSnackBarError('Servidor sem vínculo ativo');
          return;
        }
      }, error => {
        switch (error.error.code) {
          case 45:
            if (this.cadastroNovo)
              this.openNewServidorDialog();
            else
              this.snackBar.showSnackBarError('CPF não encontrado no RHNet');
            break;
          default:
            console.error(error);
            this.isProgress = false;
            this.cpfForm.enable();
            this.cpfForm.reset();
            this.inputElement.nativeElement.focus();
            this.snackBar.showSnackBarError('Erro ao conectar ao RHNet');
            break;
        }
      })
  }
  openUpdateServidorDialog(servidor) {
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      width: '540px',
      data: {
        title: 'Confirmação',
        msg: 'Nenhum vínculo ativo para esse servidor, deseja cadastra-lo como terceirizado?',
        confirmLabel: 'Sim',
        dismissLabel: 'Não'
      }
    });
    dialogRef.afterClosed()
      .pipe(take(1))
      .subscribe({
        next: (result) => {
          if (result) {
            const innerDialogRef = this.dialog.open(EditServidorComponent, {
              data: servidor.id
            });
            innerDialogRef.afterClosed()
              .pipe(take(1))
              .subscribe(servidor => {
                if (!servidor) {
                  this.isProgress = false;
                  this.cpfForm.enable();
                  this.cpfForm.reset();
                  this.inputElement.nativeElement.focus()
                  return;
                }
                this.servidorService.updateServidor(servidor.id, servidor)
                  .subscribe(
                    (res: any) => {
                      this.isProgress = false;
                      this.cpfForm.enable();
                      this.cpfForm.reset();
                      this.inputElement.nativeElement.focus()
                      let servidor = res;
                      servidor.vinculo = res.vinculos[0];
                      this.servidor.emit(servidor);
                    },
                    error => {
                      this.isProgress = false;
                      this.cpfForm.enable();
                      this.cpfForm.reset();
                      this.inputElement.nativeElement.focus()
                      console.error(error);
                      this.snackBar.showSnackBarError('Erro ao salvar novo servidor');
                    }
                  );
              });
          } else {
            this.isProgress = false;
            this.cpfForm.enable();
            this.cpfForm.reset();
            this.inputElement.nativeElement.focus()
          }
        }
      });
  }

  openNewServidorDialog() {
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      width: '540px',
      data: {
        title: 'Confirmação',
        msg: 'CPF não encontrado no RHNET. Deseja cadastrá-lo manualmente?',
        confirmLabel: 'Sim',
        dismissLabel: 'Não'
      }
    });
    dialogRef.afterClosed()
      .subscribe((result => {
        if (result) {
          const innerDialogRef = this.dialog.open(AddServidorComponent, {
            data: {
              cpf: this.cpfForm.value
            }
          });
          innerDialogRef.afterClosed()
            .subscribe(servidor => {
              if (!servidor) {
                this.isProgress = false;
                this.cpfForm.enable();
                this.cpfForm.reset();
                this.inputElement.nativeElement.focus()
                return;
              }
              this.servidorService.novoServidor(servidor)
                .subscribe(
                  (res: any) => {
                    this.isProgress = false;
                    this.cpfForm.enable();
                    this.cpfForm.reset();
                    this.inputElement.nativeElement.focus()
                    let servidor = res;
                    servidor.vinculo = res.vinculos[0];
                    this.servidor.emit(servidor);
                  },
                  error => {
                    this.isProgress = false;
                    this.cpfForm.enable();
                    this.cpfForm.reset();
                    this.inputElement.nativeElement.focus()
                    console.error(error);
                    this.snackBar.showSnackBarError('Erro ao salvar novo servidor');
                  }
                );
            });
        } else {
          this.isProgress = false;
          this.cpfForm.enable();
          this.cpfForm.reset();
          this.inputElement.nativeElement.focus()
        }
      }));
  }
  keyPressSubmit(event) {
    event.key === "Enter" ? this.getServidor(this.cpfForm.value) : null;
  }
  onBlur() {
    this.cpfForm.setErrors(null);
    this.cpfForm.updateValueAndValidity();
  }
  isFocused(a) { return document.activeElement === a }
  formatCpf(cpf: string): string {
    return cpf.match(/.{1,3}/g).join(".").replace(/\.(?=[^.]*$)/, "-");
  }
}

@Component({
  templateUrl: './vinculos-servidor.html',
  styles: ['.header{ height: 40px; margin: -22px;}']
}) export class selectVinculoComponent {
  displayedColumns = ['vinculo', 'ativo', 'grupo', 'cargo', 'funcao', 'orgaoOrigem', 'orgaoLotacao'];
  selectVinculoForm: UntypedFormControl;
  tableData: any;
  columns: Column[] = [
    { def: 'vinculo', header: 'Regime Jurídico' },
    { def: 'ativo', header: 'Ativo' },
    { def: 'grupo', header: 'Grupo' },
    { def: 'cargo', header: 'Cargo' },
    { def: 'funcao', header: 'Função' },
    { def: 'orgaoOrigem', header: 'Órgão origem' },
    { def: 'orgaoLotacao', header: 'Órgão lotação' }
  ];
  pageSizeOptions = [2, 5, 10];
  constructor(
    public dialogRef: MatDialogRef<DialogConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.selectVinculoForm = new UntypedFormControl(null);
  }

  ngOnInit(): void {
    this.tableData = this.data.vinculos.map(vinculo => {
      return {
        id: vinculo.id,
        vinculo: vinculo.regimeJuridico.descricao,
        ativo: vinculo.ativo ? 'Sim' : 'Não',
        grupo: vinculo.cargo.grupo.descricao,
        cargo: vinculo.cargo.descricao,
        funcao: vinculo.funcaoAtividade == null ? '' : vinculo.funcaoAtividade.descricao,
        orgaoOrigem: vinculo.orgao.origem.nome,
        orgaoLotacao: vinculo.orgao.lotacao.nome,
        vinculoPtr: vinculo
      }
    })
  }

  vinculoSelected(vinculo?) {
    this.dialogRef.close(vinculo.vinculoPtr);
  }
}
