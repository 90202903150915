<div class="container">
  <app-header-view titulo="Emitir Declaração" class="my-sticky-top">
    <button back-btn mat-stroked-button routerLink="..">
      <mat-icon>arrow_back</mat-icon> Voltar
    </button>
    <!-- <button right-content mat-raised-button color="primary" [disabled]="emitirDisabled" (click)="previewDeclaracao()">Emitir</button> -->
    <button
      side-content
      mat-raised-button
      color="primary"
      [disabled]="emitirDisabled"
      (click)="downloadDeclaracao()"
    >
      Emitir Declaração
    </button>
    <app-input-loading header-loading [isLoading]="downloadLoading"></app-input-loading>
    <app-input-loading header-loading [isLoading]="emitirLoading"></app-input-loading>
  </app-header-view>
  <div class="row" [formGroup]="servidorForm">
    <div class="col-lg-4 col-12">
      <app-busca-servidor
        [vinculoDialog]="false"
        icon="search"
        label="Pesquisar por CPF"
        buttonTooltip="Pesquisar servidor"
        (servidor)="buscarServidor($event)"
      ></app-busca-servidor>
    </div>
    <div class="col-lg-8 col-12">
      <mat-form-field appearance="outline">
        <mat-label apperalance="outline">Nome</mat-label>
        <input matInput type="text" formControlName="nome" readonly />
      </mat-form-field>
    </div>
  </div>
</div>
