<div class="h-100 d-flex flex-column justify-content-between">
  <nav
    [ngClass]="{ hidden: hidden }"
    [ngStyle]="{ width: hidden ? '4rem' : size + 'ch' }"
    class="nav-fases-processo"
  >
    <ng-content select="[header]"></ng-content>
    <ng-container *ngFor="let link of links; let i = index">
      <hr *ngIf="link.spacer" />
      <button
        *ngIf="!link.spacer"
        (click)="emitActive(i)"
        [ngClass]="{ 'nav-fases-label': true, active: localActive === i }"
        [routerLink]="link.route"
        [disabled]="link.disabled"
        [matTooltip]="hidden ? link.label : ''"
        matTooltipClass="example-tooltip-conf"
        matTooltipPosition="after"
      >
        <mat-icon *ngIf="link.icon">{{ link.icon }}</mat-icon
        ><span>{{ link.label }}</span>
      </button>
    </ng-container>
  </nav>
  <button
    *ngIf="navToggle"
    mat-icon-button
    class="sidenav-toggle float-right margin-top align-self-end"
    [ngClass]="{ hidden: hidden }"
    (click)="toggleHidden()"
  >
    <mat-icon>keyboard_double_arrow_left</mat-icon>
  </button>
</div>
