<div class="bg-light rounded border overflow-hidden mb-3">
  <div class="d-flex align-items-center bg-secondary text-light p-2">
    <span class="material-symbols-outlined">
      {{icone}}
    </span>
    <h5 class="ml-3 mb-0"> {{titulo}}</h5>
  </div>
  <div class="row pr-3 pl-3 pt-3">
    <ng-content></ng-content>
  </div>
</div>
